.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.duaarabic {
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  font-family: "Al Qalam Quran Majeed", _PDMS_Saleem_QuranFont, Traditional Arabic;
  font-size: 32px;
  direction: rtl;
  padding: 6px 2px
}

.duatamil {
  /* color: #000080; */
  border: 0px solid #D4DDCC;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  /* font-family: MeezanUni, "Arial Unicode MS", TheneeUni, TheneeUniTx, ThendralUni, Latha; */
  font-family: "Noto Serif Tamil", serif;
  font-style: normal;
  font-size: 17px;
  direction: ltr;
  font-optical-sizing: auto;
  /* padding: 6px 2px; */
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}



.text {
  font-variant: normal;
  color: #285d53;
  font-family: 'Open Sans', sans-serif;
  font-size: 13pt;
  font-weight: 400;
  line-height: 1.75;
  margin-top: 15px;
}

.tiles-body {
  flex: 1 1 auto;
  padding: 0 0 !important;
  color: var(--bs-card-color);
}

Footer {
  margin-top: auto;
  /*Push the footer to the bottom*/
}

.icon-bar {
  position: fixed;
  top: 70%;
  right: 0%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Style the icon bar links */
.icon-bar a {
  display: block;
  text-align: center;
  padding: 0.2rem;
  padding-bottom: 0.8rem;
  transition: all 0.3s ease;
  /* color: white; */
  font-size: 20px;
  background-color: gainsboro;
  opacity: .6;
}

nav {
  font-variant: normal;
  color: #285d53;
  font-family: 'Open Sans', sans-serif;
  /* font-size: xx-large; */
  /* font-weight: 600; */
}



.bookmarks-sidebar {
  width: 360px;
  float: right;
}

/* Basic editor styles */
.tiptap {
  >*+* {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

 

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
}

.hash-link {
  opacity: 0;
  padding-left: .5rem;
  transition: opacity var(--ifm-transition-fast);
  -webkit-user-select: none;
  user-select: none;
}

.hash-link {
  opacity: 10;
  padding-left: .5rem;
  transition: opacity var(--ifm-transition-fast);
  -webkit-user-select: none;
  user-select: none;
}

.hash-link:hover {
  opacity: 100;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

blockquote {
  color: rgba(0, 0, 0);
  font-style: italic;
  font-size: large !important;
  padding-left: 1.5em;
  border-left: 5px solid rgba(0, 0, 0, .1);
}

[class^="blockquote-footer"] {
  padding: 20px 0px 0px 0px;
}

.duabar {
  background-color: var(--bs-secondary-color) !important;
  display: block;
  padding: 0.2rem;
  transition: all 0.8s ease;
  color: white;
  font-size: 18px;
}
.duacontent {
  padding-top: 110px;
  /* background-color: #18816e; */
}
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .mob-nav-custom {
    font-size: medium;
  }
  .duabar{
    position: fixed;
    bottom: 0px;
    top:auto;
    z-index: 1030;
    float: right
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-white);
  background-color: var(--bs-info-text-emphasis);
}
.carousel-indicators {
    bottom: -17px;
}
/* .bg-body {
  background-color: rgb(189, 234, 241) !important;
} */
